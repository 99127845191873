<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/local-top-bg.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="6"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="6"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- tab 切换开始 -->
    <div class="local-tab hidden-xs-only">
      <div
        class="local-tab-item"
        :class="tab == 0 ? 'local-tab-active' : ''"
        @click="handleTab(0)"
      >
        {{ $t("message.subNav7_1") }}
      </div>
      <div
        class="local-tab-item"
        :class="tab == 1 ? 'local-tab-active' : ''"
        @click="handleTab(1)"
      >
        {{ $t("message.subNav7_2") }}
      </div>
      <div
        class="local-tab-item"
        :class="tab == 2 ? 'local-tab-active' : ''"
        @click="handleTab(2)"
      >
        {{ $t("message.subNav7_3") }}
      </div>
    </div>
    <div class="tab-content-sm hidden-sm-and-up">
      <!-- 内容开始 -->
      <div class="container">
        <el-row :gutter="20" class="tab-content-sm-btn">
          <el-col :span="8"
            ><div
              class="tab-content-sm-item"
              :class="tab == 0 ? 'tab-active-sm' : ''"
              @click="handleTab(0)"
            >
              {{ $t("message.subNav7_1") }}
            </div></el-col
          >
          <el-col :span="8"
            ><div
              class="tab-content-sm-item"
              :class="tab == 1 ? 'tab-active-sm' : ''"
              @click="handleTab(1)"
            >
              {{ $t("message.subNav7_2") }}
            </div></el-col
          >
          <el-col :span="8"
            ><div
              class="tab-content-sm-item"
              :class="tab == 2 ? 'tab-active-sm' : ''"
              @click="handleTab(2)"
            >
              {{ $t("message.subNav7_3") }}
            </div></el-col
          >
        </el-row>
      </div>
      <!-- 内容结束 -->
    </div>
    <!-- tab 切换结束 -->
    <!-- 酒店内容开始 -->
    <div v-show="tab == 0">
      <div class="middle">
        <div class="container">
          <!-- 标题部分开始 -->
          <div class="title hidden-xs-only">
            {{ $t("message.subNav7_1") }}
            <span class="small"></span>
            <span class="big"></span>
          </div>
          <!-- 标题部分结束 -->
          <!-- 图片部分开始 -->
          <div class="hotel-img">
            <img src="../assets/img/local-hotel-img.png" alt="" />
          </div>
          <!-- 图片部分结束 -->
        </div>
      </div>
    </div>
    <!-- 酒店内容结束 -->
    <!-- 餐饮内容开始 -->
    <div v-show="tab == 1">
      <div class="middle">
        <div class="container">
          <!-- 标题部分开始 -->
          <div class="title hidden-xs-only">
            {{ $t("message.subNav7_2") }}
            <span class="small"></span>
            <span class="big"></span>
          </div>
          <!-- 标题部分结束 -->
          <!-- 图片部分开始 -->
          <div class="hotel-img">
            <img src="../assets/img/local-scenic-spot.png" alt="" />
          </div>
          <!-- 图片部分结束 -->
        </div>
      </div>
    </div>
    <!-- 餐饮内容结束 -->
    <!-- 景区内容开始 -->
    <div v-show="tab == 2">
      <div class="middle">
        <div class="container">
          <!-- 标题部分开始 -->
          <div class="title hidden-xs-only">
            {{ $t("message.subNav7_3") }}
            <span class="small"></span>
            <span class="big"></span>
          </div>
          <!-- 标题部分结束 -->
          <!-- 图片部分开始 -->
          <div class="hotel-img">
            <img src="../assets/img/local-food-img.png" alt="" />
          </div>
          <!-- 图片部分结束 -->
        </div>
      </div>
    </div>
    <!-- 景区内容结束 -->
    <!-- 内容开始 -->
    <div class="container">
      <!-- 排序开始 -->
      <div class="sort">
        <!-- 单一内容开始 -->
        <div class="sort-item" v-show="tab != 1">
          <div class="sort-text">{{ $t("message.star") }}</div>
          <div class="sort-option">
            <div class="sort-option-item" @click="handleStarsSort(1)">
              <div class="sort-up-img" v-show="stars == 2 || !stars">
                <img src="../assets/svg/sort-up.svg" alt="" />
              </div>
              <div class="sort-up-img" v-show="stars == 1">
                <img src="../assets/svg/sort-up-active.svg" alt="" />
              </div>
            </div>
            <div class="sort-option-item" @click="handleStarsSort(2)">
              <div class="sort-bottom-img" v-show="stars == 1 || !stars">
                <img src="../assets/svg/sort-bottom.svg" alt="" />
              </div>
              <div class="sort-bottom-img" v-show="stars == 2">
                <img src="../assets/svg/sort-bottom-active.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 单一内容结束 -->
        <!-- 单一内容开始 -->
        <div class="sort-item">
          <div class="sort-text">{{ $t("message.distance") }}</div>
          <div class="sort-option">
            <div class="sort-option-item" @click="handleDistanceSort(1)">
              <div class="sort-up-img" v-show="distance == 2 || !distance">
                <img src="../assets/svg/sort-up.svg" alt="" />
              </div>
              <div class="sort-up-img" v-show="distance == 1">
                <img src="../assets/svg/sort-up-active.svg" alt="" />
              </div>
            </div>
            <div class="sort-option-item" @click="handleDistanceSort(2)">
              <div class="sort-bottom-img" v-show="distance == 1 || !distance">
                <img src="../assets/svg/sort-bottom.svg" alt="" />
              </div>
              <div class="sort-bottom-img" v-show="distance == 2">
                <img src="../assets/svg/sort-bottom-active.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 单一内容结束 -->
        <!-- 单一内容开始 -->
        <div class="sort-item">
          <div class="sort-text">{{ $t("message.price") }}</div>
          <div class="sort-option">
            <div class="sort-option-item" @click="handlePriceSort(1)">
              <div class="sort-up-img" v-show="price == 2 || !price">
                <img src="../assets/svg/sort-up.svg" alt="" />
              </div>
              <div class="sort-up-img" v-show="price == 1">
                <img src="../assets/svg/sort-up-active.svg" alt="" />
              </div>
            </div>
            <div class="sort-option-item" @click="handlePriceSort(2)">
              <div class="sort-bottom-img" v-show="price == 1 || !price">
                <img src="../assets/svg/sort-bottom.svg" alt="" />
              </div>
              <div class="sort-bottom-img" v-show="price == 2">
                <img src="../assets/svg/sort-bottom-active.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 单一内容结束 -->
      </div>
      <!-- 排序结束 -->
      <!-- 内容开始 -->
      <div class="table hidden-xs-only" v-if="lists.length != 0">
        <!-- 单一内容开始 -->
        <div class="table-item" v-for="(item, index) in lists" :key="index">
          <!-- 图片开始 -->
          <div class="table-item-img">
            <img :src="item.img" alt="" />
          </div>
          <!-- 图片结束 -->
          <!-- 内容开始 -->
          <div class="table-item-content">
            <!-- 标题开始 -->
            <div class="table-item-content-title">
              <div class="table-item-title-text" v-show="language == 'cn'">
                {{ item.name }}
              </div>
              <div class="table-item-title-text" v-show="language == 'en'">
                {{ item.enName }}
              </div>
              <div class="hotel-rate" v-show="tab != 1">
                <el-rate
                  v-show="tab == 0"
                  v-model="item.star"
                  disabled
                  text-color="#ff9900"
                >
                </el-rate>
                <div v-show="tab == 2" class="text-red">
                  {{ item.regType | filterStar }}
                </div>
              </div>
            </div>
            <!-- 标题结束 -->
            <!-- 电话开始 -->
            <div class="table-item-content-phone" v-if="item.phone">
              <span
                ><img src="../assets/svg/traffic-phone-icon.svg" alt="" /></span
              >{{ item.phone }}
            </div>
            <!-- 电话结束 -->
            <!-- 定位开始 -->
            <div class="table-item-content-location">
              <span
                ><img src="../assets/svg/traffic-location-icon.svg" alt=""
              /></span>
              <div v-show="language == 'cn'">{{ item.addr }}</div>
              <div v-show="language == 'en'">{{ item.enAddr }}</div>
            </div>
            <!-- 定位结束 -->
            <!-- 其他开始 -->
            <div class="table-item-content-other">
              <div class="table-item-tag">
                {{ $t("message.distanceCenter") }}
                <span>{{ item.gap }}</span>
                km
              </div>
              <div class="table-item-price" v-if="item.piace != 0">
                ¥<span>{{ item.piace }}</span>
                <label v-show="language == 'cn'">起</label>
                <label v-show="language == 'en'">+</label>
              </div>
              <div class="table-item-price" v-else>免费</div>
            </div>
            <!-- 其他结束 -->
          </div>
          <!-- 内容结束 -->
        </div>
        <!-- 单一内容结束 -->
      </div>
      <div class="table hidden-sm-and-up" v-if="lists.length != 0">
        <!-- 单一内容开始 -->
        <div class="table-item-sm" v-for="(item, index) in lists" :key="index">
          <div class="table-item-sm-top">
            <!-- 图片开始 -->
            <div class="table-item-img">
              <img :src="item.img" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 信息开始 -->
            <div class="table-item-sm-top-content">
              <div class="table-item-sm-top-box">
                <div class="table-item-title-text" v-show="language == 'cn'">
                  {{ item.name }}
                </div>
                <div class="table-item-title-text" v-show="language == 'en'">
                  {{ item.enName }}
                </div>
              </div>
            </div>
            <!-- 信息结束 -->
          </div>
          <!-- 内容开始 -->
          <div class="table-item-content mt-10">
            <div class="hotel-rate" v-show="tab != 1">
              <el-rate
                v-show="tab == 0"
                v-model="item.star"
                disabled
                text-color="#ff9900"
              >
              </el-rate>
              <div v-show="tab == 2" class="text-red">
                {{ item.regType | filterStar }}
              </div>
            </div>
            <div class="table-item-tag">
              <span v-show="language == 'cn'">距离 武汉国际博览中心</span>
              <span v-show="language == 'en'">The distance is</span>
              <span>{{ item.gap }}</span>
              km
            </div>
            <div class="table-item-sm-phone">
              <!-- 电话开始 -->
              <div class="table-item-content-phone">
                <span v-if="item.phone">
                  <img
                    src="../assets/svg/traffic-phone-icon.svg"
                    alt=""
                  /> </span
                >{{ item.phone }}
              </div>
              <!-- 电话结束 -->
              <!-- 价格开始 -->
              <div class="table-item-price" v-if="item.piace != 0">
                ¥<span>{{ item.piace }}</span>
                <label v-show="language == 'cn'">起</label>
                <label v-show="language == 'en'">+</label>
              </div>
              <div class="table-item-price" v-else>免费</div>
              <!-- 价格结束 -->
            </div>
            <!-- 定位开始 -->
            <div class="table-item-content-location">
              <span>
                <img src="../assets/svg/traffic-location-icon.svg" alt="" />
              </span>
              <div v-show="language == 'cn'">{{ item.addr }}</div>
              <div v-show="language == 'en'">{{ item.enAddr }}</div>
            </div>
            <!-- 定位结束 -->
          </div>
          <!-- 内容结束 -->
        </div>
        <!-- 单一内容结束 -->
      </div>
      <!-- 内容结束 -->
    </div>
    <!-- 内容结束 -->
    <!-- 分页开始 -->
    <div class="local-pagination">
      <div v-show="language == 'cn'">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
          :page-size="10"
          layout="prev, pager, next"
          prev-text=" 上一页 "
          next-text="下一页"
          :total="pageTotal"
          :current-page="pageIndex"
        >
        </el-pagination>
      </div>
      <div v-show="language == 'en'">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
          :page-size="10"
          layout="prev, pager, next"
          prev-text=" prev "
          next-text="next"
          :total="pageTotal"
          :current-page="pageIndex"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 分页结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      tab: 0, //0、酒店，1、餐饮，2、景区。
      stars: "", //星级默认
      distance: "", //距离默认
      price: "", //价格默认
      sort: "", //
      order: "", //
      value: 4,
      pageTotal: 0, //分页总数
      pageIndex: 1,
      lists: [], //数据
    };
  },
  mounted() {
    let num = this.$route.query.tab;
    if (num) {
      this.tab = num;
    }
    this.getDataLists();
  },
  methods: {
    // 切换tab
    handleTab(value) {
      this.tab = value;
      this.stars = "";
      this.distance = "";
      this.price = "";
      this.pageIndex = 1;
      this.getDataLists();
    },
    // 改变星级排序
    handleStarsSort(value) {
      if (this.pageIndex != 1) {
        this.pageIndex = 1;
      }
      this.stars = value;
      this.distance = "";
      this.price = "";
      this.sort = "star";
      if (value == 1) {
        this.order = "asc";
      } else if (value == 2) {
        this.order = "desc";
      }
      this.getDataLists(this.sort, this.order);
    },
    // 改变距离排序
    handleDistanceSort(value) {
      if (this.pageIndex != 1) {
        this.pageIndex = 1;
      }
      this.stars = "";
      this.distance = value;
      this.price = "";
      this.sort = "gap";
      if (value == 1) {
        this.order = "asc";
      } else if (value == 2) {
        this.order = "desc";
      }
      this.getDataLists(this.sort, this.order);
    },
    // 改变价格排序
    handlePriceSort(value) {
      if (this.pageIndex != 1) {
        this.pageIndex = 1;
      }
      this.stars = "";
      this.distance = "";
      this.price = value;
      this.sort = "piace";
      if (value == 1) {
        this.order = "asc";
      } else if (value == 2) {
        this.order = "desc";
      }
      this.getDataLists(this.sort, this.order);
    },
    // 获取数据的方法
    getDataLists(value1, value2) {
      let type = this.tab * 1 + 1;
      let page = this.pageIndex;
      //升 asc      降 desc
      //星级 star  距离 gap   价格 piace
      if (value1) {
        this.$axios
          .get(
            `/api/wuhanList?type=${type}&state=1&page=${page}&sort=${value1}&order=${value2}`,
            {}
          )
          .then((res) => {
            if (res.data.code == 0) {
              let arr = res.data.data;
              arr.data.forEach((item) => {
                item.star *= 1;
              });
              this.lists = arr.data;
              this.pageTotal = arr.count;
            }
          });
      } else {
        this.$axios
          .get(
            `/api/wuhanList?type=${type}&state=1&page=${page}&sort=&order=`,
            {}
          )
          .then((res) => {
            if (res.data.code == 0) {
              let arr = res.data.data;
              arr.data.forEach((item) => {
                item.star *= 1;
              });
              this.lists = arr.data;
              this.pageTotal = arr.count;
            }
          });
      }
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getDataLists(this.sort, this.order);
    },
  },
  filters: {
    filterStar: function (value) {
      let regType = "";
      switch (value) {
        case "0":
          regType = "";
          break;
        case "1":
          regType = "A";
          break;
        case "2":
          regType = "AA";
          break;
        case "3":
          regType = "AAA";
          break;
        case "4":
          regType = "AAAA";
          break;
        case "5":
          regType = "AAAAA";
          break;

        default:
          regType = "";
          break;
      }
      return regType;
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.local-tab {
  height: 64px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
}

.local-tab-item {
  height: 64px;
  color: #333333;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  box-sizing: border-box;
  line-height: 64px;
  padding: 0 20px;
  cursor: pointer;
}

.local-tab-active {
  color: #004bc7;
  border-bottom: 3px solid #004bc7;
}

.hotel-img {
  margin: 20px 0;
}

.hotel-img img {
  width: 100%;
}

.sort {
  height: 61px;
  background-color: #f5f5f5;
  display: flex;
}

.sort-item {
  display: flex;
  margin-right: 4px;
}

.sort-text {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 61px;
  padding: 0 8px 0 4px;
}

.sort-option {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.sort-option-item {
  cursor: pointer;
}

.sort-up-img {
  margin: 10px 10px 5px 0;
  display: flex;
}

.sort-bottom-img {
  margin: 5px 10px 10px 0;
  display: flex;
}

/* 表格开始 */
.table {
  min-height: 640px;
  padding-top: 10px;
}

.table-item-sm {
  padding: 20px 0 16px;
}

.table-item-sm + .table-item-sm {
  border-top: 1px dashed #c0c0c0;
}

.table-item-sm-top {
  display: flex;
}

.table-item-sm-top-content {
  flex: 1;
}

.table-item-sm-top-box {
  height: 66px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.table-item-img {
  width: 100px;
  height: 66px;
  background-color: #f5f5f5;
  margin-right: 16px;
  display: flex;
}

.table-item-img img {
  width: 100%;
}

.table-item-title-text {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 21px;
}

.hotel-rate {
  margin: 3px 0;
}

.food-grade {
  color: #ff3208;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 19px;
  margin: 3px 0;
}

.table-item-tag {
  color: #999999;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border: 1px solid #dddddd;
  line-height: 14px;
  display: inline-block;
  padding: 2px 8px;
}

.table-item-tag span {
  margin: 0 4px;
}

.table-item-sm-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0 8px;
}

.table-item-content {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.table-item-content-phone {
  color: #004bc7;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.table-item-content-phone span {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.table-item-content-phone span img {
  width: 100%;
}

.table-item-content-location {
  height: 24px;
  color: #004bc7;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.table-item-content-location span {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.table-item-content-location span img {
  width: 100%;
}

.table-item-content-location div {
  flex: 1;
}

.table-item-price {
  color: #004bc7;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
}

.table-item-price span {
  font-size: 24px;
  font-weight: bold;
  margin: 0 4px;
}
/* 表格结束 */

.local-pagination {
  padding: 40px 0;
  display: flex;
  justify-content: center;
}

.local-pagination >>> .btn-prev,
.local-pagination >>> .btn-next {
  padding: 0 8px;
}

@media (min-width: 768px) {
  .hotel-img {
    margin: 40px 0;
  }

  .sort-text {
    color: #333333;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 61px;
    padding: 0 20px;
  }

  .sort-option {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .sort-option-item {
    cursor: pointer;
  }

  .sort-up-img {
    margin: 10px 10px 5px 0;
    display: flex;
  }

  .sort-bottom-img {
    margin: 5px 10px 10px 0;
    display: flex;
  }

  .table {
    min-height: 1040px;
    padding-top: 10px;
  }

  .table-item {
    padding: 30px 0;
    display: flex;
  }

  .table-item + .table-item {
    border-top: 1px dashed #c0c0c0;
  }

  .table-item-img {
    width: 300px;
    height: 200px;
    background-color: #f5f5f5;
    margin-right: 30px;
  }

  .table-item-content {
    width: 870px;
  }

  .table-item-content-title {
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-item-title-text {
    color: #333333;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  .hotel-rate {
    width: 140px;
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }

  .food-grade {
    width: 120px;
    color: #ff3208;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 32px;
    text-align: right;
    margin: 0;
  }

  .table-item-content-phone {
    height: 24px;
    color: #004bc7;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
  }

  .table-item-content-phone span {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .table-item-content-location {
    height: 24px;
    color: #004bc7;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
  }

  .table-item-content-location span {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .table-item-content-other {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-item-tag {
    color: #999999;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    border: 1px solid #dddddd;
    line-height: 19px;
    padding: 7px 10px;
  }

  .table-item-price {
    color: #004bc7;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
  }

  .table-item-price span {
    font-size: 36px;
    font-weight: bold;
    margin: 0 4px;
  }

  .local-pagination {
    padding: 50px 0 80px;
    display: flex;
    justify-content: center;
  }

  .local-pagination >>> .btn-prev,
  .local-pagination >>> .btn-next {
    padding: 0 8px;
  }
}

.local-pagination >>> .btn-prev,
.local-pagination >>> .btn-next {
  padding: 0 8px;
}
</style>
